// @flow

import React from 'react';
import { observer } from 'mobx-react';
import { graphql, StaticQuery } from 'gatsby';
import Helmet from "react-helmet";
import getImageUrl from "../../utils/get-image-url";

type MetadataProps = {
  data: {
    title?: string,
    description?: string,
    image?: any,
    ogTitle?: string,
    ogDescription?: string,
    twitterCard?: string
  },
  location: {
    origin: string,
    pathname: string
  }
}

@observer
class Metadata extends React.Component<MetadataProps, any> {

  render() {

    return (
      <StaticQuery
        query={graphql`
            query {
              data: contentfulMetadata(contentful_id: {eq: "3okJ9UdEacAK4EocE60iYE"}) {
                title
                description
                image {
                  file {
                    url
                  }
                }
                ogTitle
                ogDescription
                twitterCard
              }
            }
          `}
        render={data => (
          <Helmet
            title={this.props.data.title ? this.props.data.title : data.data.title}
            meta={[
              { name: 'description',
                content: this.props.data.description ?
                  this.props.data.description :
                  data.data.description
              },
              { name: 'og:type',
                content: 'website'
              },
              { name: 'og:url',
                content: `${this.props.location.origin}${this.props.location.pathname}`
              },
              { name: 'og:title',
                content: this.props.data.ogTitle ?
                  this.props.data.ogTitle :
                  this.props.data.title ?
                  this.props.data.title :
                  data.data.ogTitle
              },
              { name: 'og:description',
                content: this.props.data.ogDescription ?
                  this.props.data.ogDescription :
                  this.props.data.description ?
                    this.props.data.description :
                    data.data.ogDescription
              },
              { name: 'og:image',
                content: this.props.data.image ?
                  getImageUrl(this.props.data.image) :
                  getImageUrl(data.data.image)
              },
              { name: 'twitter:card',
                content: this.props.data.twitterCard ?
                  this.props.data.twitterCard :
                  data.data.twitterCard
              },
            ]}
          >
            <link rel="stylesheet" href="https://use.typekit.net/ubx2ugm.css"></link>
            <html lang="en" />
          </Helmet>
        )}
      />
    )
  }
}

export default Metadata;
